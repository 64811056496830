import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ResizeOverlayComponent } from "./customer/resize-overlay/resize-overlay.component";

@Component({
    selector: 'servefast-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    
    imports: [RouterModule, ResizeOverlayComponent]
})
export class AppComponent {}
